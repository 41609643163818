// src/api/beautician.api.js
const API_URL = process.env.REACT_APP_API_URL;

const beauticianApi = {
    // Obtenir toutes les esthéticiennes
    getAllBeauticians: async () => {
        try {
            const response = await fetch(`${API_URL}/beauticians`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des esthéticiennes');
        }
    },

    // Obtenir une esthéticienne par ID
    getBeauticianById: async (id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'esthéticienne');
        }
    },

    // Créer une nouvelle esthéticienne
    createBeautician: async (token, beauticianData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(beauticianData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création de l\'esthéticienne');
        }
    },

    // Mettre à jour une esthéticienne
    updateBeautician: async (token, id, beauticianData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(beauticianData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de l\'esthéticienne');
        }
    },

    // Supprimer une esthéticienne
    deleteBeautician: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression de l\'esthéticienne');
        }
    },

    // Rechercher des esthéticiennes par localisation
    searchBeauticians: async (searchParams) => {
        try {
            const queryString = new URLSearchParams(searchParams).toString();
            const response = await fetch(`${API_URL}/beauticians/search?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la recherche d\'esthéticiennes');
        }
    },

    // Mettre à jour la disponibilité d'une esthéticienne
    updateAvailability: async (token, id, availability) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/availability`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ is_available: availability })
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de la disponibilité');
        }
    },
    
    // Gérer le portfolio
    // Ajouter une photo au portfolio d'une esthéticienne
    addPortfolioPhoto: async (token, id, photoData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(photoData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de l\'ajout de la photo au portfolio');
        }
    },

    // Supprimer une photo du portfolio d'une esthéticienne
    deletePortfolioPhoto: async (token, id, photoId) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio/${photoId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression de la photo du portfolio');
        }
    }
};

export default beauticianApi;