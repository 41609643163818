import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, X, Eye } from 'lucide-react';
import styles from './PortFolio.module.css';

const Portfolio = ({ beauticianName }) => {
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if mobile and update on resize
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    // Pour test, utilisez des images de placeholder
    setImages([
      'https://images.unsplash.com/photo-1516975080664-ed2fc6a32937',
      'https://images.unsplash.com/photo-1560066984-138dadb4c035',
      'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f',
      'https://images.unsplash.com/photo-1526045478516-99145907023c',
      'https://images.unsplash.com/photo-1457972729786-0411a3b2b626',
      'https://images.unsplash.com/photo-1583001931096-959e19c21ca2'
    ]);
  }, [beauticianName]);

  const nextSlide = useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentSlide((prev) => (prev + 1) % images.length);
    setTimeout(() => setIsAnimating(false), 500);
  }, [isAnimating, images.length]);

  const prevSlide = useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    setTimeout(() => setIsAnimating(false), 500);
  }, [isAnimating, images.length]);

  const getVisibleImages = useCallback(() => {
    if (isMobile) {
      return [images[currentSlide]];
    }
    if (images.length <= 3) return images;
    
    const visibleImages = [];
    for (let i = 0; i < 3; i++) {
      const index = (currentSlide + i) % images.length;
      visibleImages.push(images[index]);
    }
    return visibleImages;
  }, [images, currentSlide, isMobile]);

  useEffect(() => {
    if (images.length <= (isMobile ? 1 : 3)) return;

    const timer = setInterval(nextSlide, 2000);
    return () => clearInterval(timer);
  }, [images.length, nextSlide, isMobile]);

  return (
    <div className={styles.portfolioContainer}>
      <div className={styles.portfolioContent}>
        <h2 className={styles.portfolioTitle}>Mes Réalisations</h2>
        
        <div className={styles.portfolioWrapper}>
          {images.length > 0 ? (
            <div className={styles.portfolioSlider}>
              {/* Navigation buttons */}
              {images.length > (isMobile ? 1 : 3) && (
                <>
                  <button
                    onClick={prevSlide}
                    className={`${styles.navButton} ${styles.prevButton}`}
                  >
                    <ChevronLeft size={24} />
                  </button>
                  <button
                    onClick={nextSlide}
                    className={`${styles.navButton} ${styles.nextButton}`}
                  >
                    <ChevronRight size={24} />
                  </button>
                </>
              )}

              {/* Images grid */}
              <div className={`${styles.portfolioGrid} ${isMobile ? styles.mobileGrid : ''}`}>
                {getVisibleImages().map((img, index) => (
                  <div
                    key={`${currentSlide}-${index}`}
                    className={styles.portfolioItem}
                    onClick={() => {
                      setModalImageIndex((currentSlide + index) % images.length);
                      setIsModalOpen(true);
                    }}
                  >
                    <div className={styles.imageWrapper}>
                      <img
                        src={img}
                        alt={`Réalisation ${index + 1}`}
                        className={styles.portfolioImage}
                      />
                      <div className={styles.portfolioOverlay}>
                        <Eye size={24} className={styles.eyeIcon} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Progress dots */}
              {images.length > (isMobile ? 1 : 3) && (
                <div className={styles.paginationDots}>
                  {Array.from({ length: isMobile ? images.length : Math.ceil(images.length / 3) }).map((_, index) => (
                    <button
                      key={index}
                      className={`${styles.dot} ${
                        (isMobile ? currentSlide : Math.floor(currentSlide / 3)) === index ? styles.activeDot : ''
                      }`}
                      onClick={() => setCurrentSlide(isMobile ? index : index * 3)}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.noImages}>
              <p>Aucune réalisation disponible</p>
            </div>
          )}
        </div>

        {/* Voir tout button */}
        {images.length > 0 && (
          <button
            onClick={() => setIsModalOpen(true)}
            className={styles.viewAllButton}
          >
            <Eye size={20} />
            <span>Voir toutes les réalisations</span>
          </button>
        )}
      </div>

      {/* Modal Gallery */}
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContainer}>
            <img
              src={images[modalImageIndex]}
              alt={`Réalisation ${modalImageIndex + 1}`}
              className={styles.modalImage}
            />
            
            <button
              onClick={() => setModalImageIndex((prev) => 
                prev === 0 ? images.length - 1 : prev - 1
              )}
              className={`${styles.modalNav} ${styles.modalPrev}`}
            >
              <ChevronLeft size={24} />
            </button>
            
            <button
              onClick={() => setModalImageIndex((prev) => 
                prev === images.length - 1 ? 0 : prev + 1
              )}
              className={`${styles.modalNav} ${styles.modalNext}`}
            >
              <ChevronRight size={24} />
            </button>

            <button
              onClick={() => setIsModalOpen(false)}
              className={styles.modalClose}
            >
              <X size={24} />
            </button>

            <div className={styles.modalCounter}>
              {modalImageIndex + 1} / {images.length}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Portfolio;