import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { divIcon } from 'leaflet';
import { useNavigate } from 'react-router-dom';
import { getProfileUrl } from '../../utils/domainUtils';
import 'leaflet/dist/leaflet.css';
import './BeauticiansMap.css';
import BeauticianCardPopup from './BeauticianCardPopup';

// Composant pour mettre à jour la vue de la carte
const MapUpdater = ({ selectedLocation, radius }) => {
  const map = useMap();

  useEffect(() => {
    if (selectedLocation) {
      map.setView([selectedLocation.lat, selectedLocation.lng], 13);
    }
  }, [map, selectedLocation]);

  return null;
};

const BeauticiansMap = ({ beauticians, selectedLocation, radius, selectedBeautician }) => {
  const navigate = useNavigate();
  const defaultPosition = [46.603354, 1.888334]; // Centre de la France

  const handleMarkerClick = (beautician) => {
    const profileUrl = getProfileUrl(beautician);
    navigate(profileUrl);
  };

  const createCustomIcon = (isSelected = false) => {
    return divIcon({
      html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 36" width="24" height="36">
              <path d="M12 0C5.373 0 0 5.373 0 12c0 8.432 10.761 23.049 11.383 23.849a0.8 0.8 0 0 0 1.234 0C13.239 35.049 24 20.432 24 12c0-6.627-5.373-12-12-12z" 
                fill="${isSelected ? '#a00f2f' : '#bd193b'}"
                class="marker-pin"/>
              <circle cx="12" cy="12" r="6" fill="white"/>
            </svg>`,
      className: 'custom-marker-icon',
      iconSize: [24, 36],
      iconAnchor: [12, 36],
      popupAnchor: [0, -34]
    });
  };

  return (
    <MapContainer
      center={selectedLocation || defaultPosition}
      zoom={selectedLocation ? 13 : 6}
      style={{ height: '100%', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapUpdater selectedLocation={selectedLocation} radius={radius} />
      {beauticians.map((beautician) => (
        <Marker
          key={beautician.id}
          position={[beautician.coordinates.lat, beautician.coordinates.lng]}
          icon={createCustomIcon(selectedBeautician?.id === beautician.id)}
          eventHandlers={{
            click: () => handleMarkerClick(beautician),
            mouseover: (e) => e.target.openPopup(),
            mouseout: (e) => e.target.closePopup(),
          }}
        >
          <Popup closeButton={false}>
            <BeauticianCardPopup beautician={beautician} />
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default BeauticiansMap;