import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getProfileUrl } from '../../utils/domainUtils';
import './BeauticianCardPopup.css';

const BeauticianCardPopup = ({ beautician }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    const profileUrl = getProfileUrl(beautician);
    navigate(profileUrl);
  };

  // Ne prendre que les deux premières spécialités pour éviter l'encombrement
  const displayedSpecialties = beautician.specialties.slice(0, 2);

  return (
    <div className="beautician-card-popup" onClick={handleCardClick}>
      <img src={beautician.image} alt={beautician.name} className="card-popup-image" />
      <div className="card-popup-content">
        <h4 className="card-popup-name">{beautician.name}</h4>
        <div className="card-popup-specialties">
          {displayedSpecialties.map((specialty, index) => (
            <span key={index} className="card-popup-specialty">
              {specialty}
              {index < displayedSpecialties.length - 1 ? ' • ' : ''}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeauticianCardPopup;