import "./Dashboard.css"

import { Outlet } from "react-router-dom"

import Header from "../../components/dashboard/Header"
import Footer from "../../components/dashboard/Footer"
import UserCreation from "../../components/dashboard/UserCreation"
import { useState } from "react"

const Dashboard = () => {
    const [showUserCreationModal, setShowUserCreationModal] = useState(false)

    return (
        <div className="w-100 pb-2 h-[100vh] flex flex-col gap-2">
            <Header createUser={setShowUserCreationModal} />

            <div className="mx-4 mt-2 overflow-y-auto">
                <Outlet />
            </div>

            <Footer />

            { showUserCreationModal && <UserCreation close={setShowUserCreationModal} /> }
        </div>
    )
}

export default Dashboard