import React, { useState } from 'react';
import BeauticianCard from './BeauticianCard';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { beauticians } from '../../data/beauticiansData';
import './BeauticianCardList.css';

const BeauticianCardList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  
  const cardsPerPage = 10;
  const totalPages = Math.ceil(beauticians.length / cardsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const getCurrentCards = () => {
    const start = currentPage * cardsPerPage;
    return beauticians.slice(start, start + cardsPerPage);
  };

  return (
    <div className="beautician-list-container">
      <div className="beautician-grid">
        {getCurrentCards().map((beautician) => (
          <div key={beautician.id} className="beautician-grid-item">
            <BeauticianCard beautician={beautician} />
          </div>
        ))}
      </div>
      
      {totalPages > 1 && (
        <div className="carousel-controls">
          <button 
            className="carousel-button" 
            onClick={handlePrevPage}
            aria-label="Page précédente"
          >
            <ChevronLeft size={24} />
          </button>
          <div className="carousel-indicators">
            {Array.from({ length: totalPages }).map((_, index) => (
              <span
                key={index}
                className={`carousel-dot ${index === currentPage ? 'active' : ''}`}
                onClick={() => setCurrentPage(index)}
              />
            ))}
          </div>
          <button 
            className="carousel-button" 
            onClick={handleNextPage}
            aria-label="Page suivante"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      )}
    </div>
  );
};

export default BeauticianCardList;