import React, { useState, useEffect, useCallback } from 'react';
import { Star, X, ChevronLeft, ChevronRight } from 'lucide-react';
import './Reviews.css';

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          size={16}
          className={`star ${star <= rating ? 'filled' : ''}`}
          fill={star <= rating ? '#bd193b' : 'none'}
          stroke={star <= rating ? '#bd193b' : '#868e96'}
        />
      ))}
    </div>
  );
};

const ReviewCard = ({ review, onClick }) => {
  return (
    <div className="review-card" onClick={onClick}>
      <div className="review-header">
        <StarRating rating={review.rating} />
        <span className="reviewer-name">{review.name}</span>
      </div>
      <p className="review-content">{review.content}</p>
      <span className="review-date">{review.date}</span>
    </div>
  );
};

const Modal = ({ isOpen, onClose, reviews, selectedReviewIndex }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <X size={24} />
        </button>
        <div className="modal-reviews">
          <h2>Avis clients</h2>
          <div className="modal-reviews-list">
            {reviews.map((review, index) => (
              <div 
                key={index} 
                className={`modal-review ${index === selectedReviewIndex ? 'highlighted' : ''}`}
                id={index === selectedReviewIndex ? 'selected-review' : ''}
              >
                <div className="modal-review-header">
                  <div className="modal-review-info">
                    <StarRating rating={review.rating} />
                    <span className="reviewer-name">{review.name}</span>
                  </div>
                  <span className="review-date">{review.date}</span>
                </div>
                <p className="review-content">{review.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Reviews = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const reviews = [
    {
      name: "Sophie M.",
      rating: 5,
      content: "Une expérience exceptionnelle ! Les soins sont personnalisés et l'ambiance est très relaxante. Je recommande particulièrement le soin du visage anti-âge qui a fait des merveilles sur ma peau.",
      date: "15 mars 2024"
    },
    {
      name: "Marie L.",
      rating: 4,
      content: "Très professionnelle et à l'écoute. L'épilation est pratiquement indolore et les résultats sont impeccables. Le seul petit bémol serait le temps d'attente parfois un peu long.",
      date: "2 mars 2024"
    },
    {
      name: "Julie D.",
      rating: 5,
      content: "La manucure semi-permanente tient vraiment bien, déjà 3 semaines et toujours parfaite ! L'accueil est chaleureux et les prix sont très corrects pour la qualité du service.",
      date: "28 février 2024"
    },
    {
      name: "Camille B.",
      rating: 5,
      content: "Je suis ravie d'avoir découvert ce salon ! Le soin du visage était divin et mon teint est resplendissant. L'esthéticienne prend le temps d'expliquer chaque étape du soin.",
      date: "20 février 2024"
    },
    {
      name: "Laura P.",
      rating: 4,
      content: "Très satisfaite de mon épilation au fil des sourcils. Le résultat est naturel et précis. Le salon est très propre et l'ambiance est agréable.",
      date: "15 février 2024"
    },
    {
      name: "Emma R.",
      rating: 5,
      content: "Une vraie professionnelle qui connaît son métier ! Le massage relaxant était juste parfait. Je me suis sentie comme sur un nuage pendant et après le soin.",
      date: "10 février 2024"
    }
  ];

  const totalPages = Math.ceil(reviews.length / 3);

  const nextPage = useCallback(() => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  }, [totalPages]);

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        nextPage();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isPaused, nextPage]);

  const handleReviewClick = (index) => {
    setSelectedReviewIndex(index);
    setIsModalOpen(true);
  };

  const startIndex = currentPage * 3;
  const visibleReviews = reviews.slice(startIndex, startIndex + 3);

  return (
    <div className="reviews-container">
      <h2 className="reviews-title">Avis de nos clients</h2>
      <div 
        className="reviews-carousel"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <button className="carousel-button prev" onClick={prevPage}>
          <ChevronLeft size={24} />
        </button>
        
        <div className="reviews-grid">
          {visibleReviews.map((review, index) => (
            <ReviewCard
              key={startIndex + index}
              review={review}
              onClick={() => handleReviewClick(startIndex + index)}
            />
          ))}
        </div>

        <button className="carousel-button next" onClick={nextPage}>
          <ChevronRight size={24} />
        </button>

        <div className="carousel-dots">
          {Array(totalPages).fill(0).map((_, index) => (
            <button
              key={index}
              className={`carousel-dot ${currentPage === index ? 'active' : ''}`}
              onClick={() => setCurrentPage(index)}
            />
          ))}
        </div>
      </div>
      
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        reviews={reviews}
        selectedReviewIndex={selectedReviewIndex}
      />
    </div>
  );
};

export default Reviews;