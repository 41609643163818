import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginModal.css';
import logo from '../../assets/images/8.png';
import userApi from '../../api/user.api';

const LoginModal = ({ isOpen, onClose, onSignupClick, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Fonction pour déterminer l'URL de base
  const getBaseUrl = () => {
    const currentUrl = window.location.origin;
    // Si nous sommes sur localhost ou planibeauty.fr, retourner l'URL correspondante
    return currentUrl.includes('localhost') ? 'http://localhost:3000' : 'https://planibeauty.fr';
  };

  // Fonction pour gérer la redirection
  const handleRedirection = (userType) => {
    const baseUrl = getBaseUrl();
    
    switch (userType) {
      case 'admin':
      case 'operator':
      case 'beautician':
        window.location.href = `${baseUrl}/dashboard`;
        break;
      case 'client':
        // Pour les clients, rester sur la page actuelle
        onClose();
        break;
      default:
        // Par défaut, rester sur la page actuelle
        onClose();
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await userApi.login({
        email: email.trim(),
        password: password
      });

      if (response.token) {
        // Stockage du token et des informations utilisateur
        localStorage.setItem('userToken', response.token);
        localStorage.setItem('userId', response.userId);
        localStorage.setItem('userType', response.userType);
        if (response.first_name) {
          localStorage.setItem('userName', response.first_name);
        }
        
        // Réinitialisation du formulaire
        setEmail('');
        setPassword('');
        
        // Callback de succès si défini
        if (onLoginSuccess) {
          onLoginSuccess(response);
        }

        // Gérer la redirection en fonction du type d'utilisateur
        handleRedirection(response.userType);

      } else {
        setError('Une erreur est survenue lors de la connexion');
      }
    } catch (error) {
      setError(error.message || 'Email ou mot de passe incorrect');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className={`modal-content ${isLoading ? 'loading' : ''}`}>
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="modal-logo">
          <img src={logo} alt="Logo" />
        </div>

        <h2 className="modal-title">Connexion</h2>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Adresse email"
              disabled={isLoading}
              required
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mot de passe"
              disabled={isLoading}
              required
            />
          </div>

          <div className="form-links">
            <a href="/forgot-password" className="forgot-password">
              Mot de passe oublié ?
            </a>
          </div>

          <button 
            type="submit" 
            className="login-button"
            disabled={isLoading}
          >
            {isLoading ? 'Connexion en cours...' : 'Se connecter'}
          </button>

          <div className="signup-link">
            Pas encore membre ? 
            <button 
              type="button" 
              onClick={onSignupClick} 
              className="link-button"
              disabled={isLoading}
            >
              Créer un compte
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;