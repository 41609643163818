import "./Users.css"
import UserData from "../../../components/dashboard/UserData"

const Users = () => {
    let datas = [
        {
            "id": "1",
            "nom_prenom": "Dupont Jean",
            "adresse_postale": "12 Rue de la Liberté",
            "code_postal": "75001",
            "ville": "Paris",
            "numero_telephone": "01 23 45 67 89",
            "prestation_proposee": "Coaching sportif",
            "derniere_connexion": "2024-11-15",
            "date_creation": "2024-10-15"
        },
        {
            "id": "2",
            "nom_prenom": "Martin Claire",
            "adresse_postale": "45 Avenue des Champs-Élysées",
            "code_postal": "75008",
            "ville": "Paris",
            "numero_telephone": "01 98 76 54 32",
            "prestation_proposee": "Conseil en marketing digital",
            "derniere_connexion": "2024-11-16",
            "date_creation": "2024-11-01"
        },
        {
            "id": "2",
            "nom_prenom": "Martin Claire",
            "adresse_postale": "45 Avenue des Champs-Élysées",
            "code_postal": "75008",
            "ville": "Paris",
            "numero_telephone": "01 98 76 54 32",
            "prestation_proposee": "Conseil en marketing digital",
            "derniere_connexion": "2024-11-16",
            "date_creation": "2024-11-01"
        },
        {
            "id": "2",
            "nom_prenom": "Martin Claire",
            "adresse_postale": "45 Avenue des Champs-Élysées",
            "code_postal": "75008",
            "ville": "Paris",
            "numero_telephone": "01 98 76 54 32",
            "prestation_proposee": "Conseil en marketing digital",
            "derniere_connexion": "2024-11-16",
            "date_creation": "2024-11-01"
        },
        {
            "id": "2",
            "nom_prenom": "Martin Claire",
            "adresse_postale": "45 Avenue des Champs-Élysées",
            "code_postal": "75008",
            "ville": "Paris",
            "numero_telephone": "01 98 76 54 32",
            "prestation_proposee": "Conseil en marketing digital",
            "derniere_connexion": "2024-11-16",
            "date_creation": "2024-11-01"
        },
        {
            "id": "2",
            "nom_prenom": "Martin Claire",
            "adresse_postale": "45 Avenue des Champs-Élysées",
            "code_postal": "75008",
            "ville": "Paris",
            "numero_telephone": "01 98 76 54 32",
            "prestation_proposee": "Conseil en marketing digital",
            "derniere_connexion": "2024-11-16",
            "date_creation": "2024-11-01"
        }
    ]

    return (
        <main>
            {
                datas.map((data) => {
                    return (
                        <UserData data={data} />
                    )
                })
            }

            {/* <section className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-80">
                <form className="w-[40%] p-4 flex flex-col gap-2 bg-white rounded" onSubmit={(e) => e.preventDefault()}>
                    <h1 className="text-center">Modifier</h1>
                    <div className="flex flex-col gap-3">
                        <label htmlFor="" className="col-span-4">Nom</label>
                        <input type="text" className="px-3 py-2 col-span-4 border shadow-sm" placeholder="Nom" />
                    </div>
                    <div className="flex flex-col gap-3">
                        <label htmlFor="">Prénom(s)</label>
                        <input type="text" className="px-3 py-2 col-span-4 border shadow-sm" placeholder="Prénom(s)" />
                    </div>
                    <div className="grid grid-cols-8 gap-3">
                        <label htmlFor="" className="col-span-6">Addresse</label>
                        <label htmlFor="" className="col-span-2">Code postal</label>
                    </div>
                    <div className="grid grid-cols-8 gap-3">
                        <input type="text" className="px-3 py-2 col-span-6 border shadow-sm" placeholder="Addresse" />
                        <input type="text" className="px-3 py-2 col-span-2 border shadow-sm" placeholder="Code" />
                    </div>
                    <div className="flex flex-col gap-3">
                        <label htmlFor="">Ville</label>
                        <input type="text" className="px-3 py-2 col-span-4 border shadow-sm" placeholder="Ville" />
                    </div>
                    <div className="flex flex-col gap-3">
                        <label htmlFor="">Numéro de téléphone</label>
                        <input type="text" className="px-3 py-2 col-span-4 border shadow-sm" placeholder="Numéro de téléphone" />
                    </div>
                    <div className="flex items-center justify-end gap-3">
                        <button className="px-4 py-2 bg-green-700 text-white rounded">Valider</button>
                        <button className="px-4 py-2 border border-gray-700 rounded">Annuler</button>
                    </div>
                </form>
                <form className="w-[40%] p-4 flex flex-col gap-3 bg-white rounded">
                    <h2 className="text-center">Etes vous sûre de vouloir supprimer ces données?</h2>
                    <div className="flex items-center justify-end gap-3">
                        <button className="px-4 py-2 bg-green-700 text-white rounded">Oui</button>
                        <button className="px-4 py-2 border border-gray-700 rounded">Annuler</button>
                    </div>
                </form>
            </section> */}
        </main>
    )
}

export default Users