import Logo from "../../assets/images/3.png"
import { Link } from "react-router-dom"
import { FaUserCircle, FaPencilAlt, FaSearch } from "react-icons/fa"

const Header = ({ createUser }) => {
    return <>
        <nav className="relative px-14 flex items-center justify-between shadow">
            <a href="/">
                <img width="70" src={Logo} alt="Logo" />
            </a>

            <ul className="absolute left-[50%] -translate-x-[50%] flex gap-4">
                <li>
                    <Link to="/dashboard" className="anchor active">Utilisateur</Link>
                </li>
                <li>
                    <Link to="/dashboard/client" className="anchor">Client</Link>
                </li>
            </ul>

            <Link to="/dashboard/profile" className="flex gap-2 items-center text-gray-800">
                <span  className="text-4xl">
                    <FaUserCircle />
                </span>
            </Link>
        </nav>
        <section className="w-full flex items-center justify-between px-4">
            <button
                onClick={() => createUser(true)}
                className="px-4 py-2 text-white bg-[#bd193b] flex items-center gap-2 rounded shadow active:scale-[101%] duration-200"
            >
                <FaPencilAlt />
                Créer une fiche utilisateur
            </button>

            <form onSubmit={(e) => e.preventDefault()} className="relative w-[25%] flex items-center gap-2 bg-white px-4 border rounded shadow">
                <FaSearch />
                <input type="text" className="w-full p-2 outline-none" placeholder="Rechercher un utilisateur..."/>
                {/* <aside className="absolute left-0 translate-y-[120%] p-4 w-full border shadow"></aside> */}
            </form>
        </section>

        <section className="relative px-4 py-3 w-full flex items-center justify-between row-span-1">
            <div className="flex gap-4">
                <aside className="flex gap-2 items-center">
                    <input type="radio" id="all" name="filter-radio"/><label htmlFor="all">Tout</label>
                </aside>
                <aside className="flex gap-2 items-center">
                    <input type="radio" id="none" name="filter-radio" checked/><label htmlFor="none">Aucun</label>
                </aside>
                <select name="to-display" id="to-display" className="py-1 px-2 border shadow rounded">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                </select>
            </div>

            <span className="absolute left-[50%] font-semibold text-gray-600 -translate-x-[50%]">
                Total <span>0</span> utilisateurs sur <span>2</span> - Pages <span>1</span> / <span>1</span>
            </span>

            <select name="filter" id="filter" className="py-1 px-2 border shadow rounded">
                <option value="0">Filtres</option>
                <option value="1">ID</option>
                <option value="2">Nom et prénom</option>
                <option value="3">Adresse postal</option>
                <option value="4">Code postal</option>
                <option value="5">Ville</option>
                <option value="6">Numéro de téléphone</option>
                <option value="7">Préstation proposée</option>
                <option value="8">Dernière connexion</option>
                <option value="9">Date de création</option>
            </select>
        </section>

        <header className="mx-4 py-2 bg-[#bd193b] text-white font-bold">
            <span className="text-center col-span-2">ID</span>
            <span className="text-center col-span-4">Nom et prénom</span>
            <span className="text-center col-span-3">Adresse postal</span>
            <span className="text-center col-span-3">Code postal</span>
            <span className="text-center col-span-2">Ville</span>
            <span className="text-center col-span-4">Numéro de téléphone</span>
            <span className="text-center col-span-4">Préstation proposée</span>
            <span className="text-center col-span-4">Dernière connexion</span>
            <span className="text-center col-span-4 border-r-0">Date de création</span>
        </header>  
    </>
}

export default Header