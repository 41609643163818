import React from 'react';
import { Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getProfileUrl } from '../../utils/domainUtils';
import './BeauticianCard.css';

const BeauticianCard = ({ beautician }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    const profileUrl = getProfileUrl(beautician);
    navigate(profileUrl);
  };

  const renderStars = (rating) => {
    if (!rating) return null;
    
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 === 0.5;
    const totalStars = 5;

    // Étoiles pleines
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <Star 
          key={`full-${i}`}
          className="star filled"
          size={12}
          fill="#bd193b"
          stroke="#bd193b"
          strokeWidth={2}
        />
      );
    }

    // Demi-étoile si nécessaire
    if (hasHalfStar) {
      stars.push(
        <div key="half" className="half-star-container">
          <Star 
            className="star half-filled"
            size={12}
            fill="#bd193b"
            stroke="#bd193b"
            strokeWidth={2}
          />
          <Star 
            className="star empty-half"
            size={12}
            fill="none"
            stroke="#bd193b"
            strokeWidth={2}
          />
        </div>
      );
    }

    // Étoiles vides restantes
    const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <Star 
          key={`empty-${i}`}
          className="star"
          size={12}
          fill="none"
          stroke="#bd193b"
          strokeWidth={2}
        />
      );
    }

    return stars;
  };

  if (!beautician || !beautician.image || !beautician.name) {
    return null;
  }

  return (
    <div className="beautician-card" onClick={handleCardClick} role="button" tabIndex={0}>
      <div className="card-image-container">
        <img src={beautician.image} alt={beautician.name} className="card-image" />
      </div>
      <div className="card-content">
        <div className="card-header">
          <h3 className="card-name">{beautician.name}</h3>
          <span className="card-city">{beautician.city}</span>
        </div>
        <div className="card-footer">
          <div className="card-rating">
            {renderStars(beautician.rating)}
          </div>
          <div className="card-reviews">
            <span className="reviews-count">{beautician.reviewsCount || 0}</span>
            <span className="reviews-text">avis</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeauticianCard;