import React, { useState, useEffect } from 'react';
import beauticianApi from '../../api/beautician.api';
import { Plus, Search, Edit2, Trash2 } from 'lucide-react';
import './BeauticianAdmin.css';
import BeauticianForm from './BeauticianForm';

const BeauticianAdmin = () => {
  const [beauticians, setBeauticians] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingBeautician, setEditingBeautician] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBeauticians();
  }, []);

  const fetchBeauticians = async () => {
    try {
      setLoading(true);
      const data = await beauticianApi.getAllBeauticians();
      setBeauticians(data);
    } catch (error) {
      console.error('Erreur:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setEditingBeautician(null);
    setIsModalOpen(true);
  };

  const handleEdit = (beautician) => {
    setEditingBeautician(beautician);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette esthéticienne ?')) {
      try {
        const token = localStorage.getItem('token'); // Récupérer le token d'authentification
        await beauticianApi.deleteBeautician(token, id);
        await fetchBeauticians();
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
      }
    }
  };

  const filteredBeauticians = beauticians.filter(beautician => 
    beautician.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    beautician.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    beautician.city.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="beautician-admin">
      <div className="admin-header">
        <h1>Gestion des Esthéticiennes</h1>
        <div className="header-actions">
          <div className="search-box">
            <Search size={20} />
            <input
              type="text"
              placeholder="Rechercher une esthéticienne..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="add-button" onClick={handleAdd}>
            <Plus size={20} />
            Ajouter une esthéticienne
          </button>
        </div>
      </div>

      {loading ? (
        <div className="loading">Chargement...</div>
      ) : (
        <div className="beauticians-table-container">
          <table className="beauticians-table">
            <thead>
              <tr>
                <th>Photo</th>
                <th>Nom</th>
                <th>Email</th>
                <th>Ville</th>
                <th>Catégorie</th>
                <th>Note</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredBeauticians.map((beautician) => (
                <tr key={beautician.id}>
                  <td>
                    <img
                      src={beautician.profile_photo || '/default-avatar.png'}
                      alt={`${beautician.first_name} ${beautician.last_name}`}
                      className="profile-thumb"
                    />
                  </td>
                  <td>{`${beautician.first_name} ${beautician.last_name}`}</td>
                  <td>{beautician.email}</td>
                  <td>{beautician.city}</td>
                  <td>{beautician.budget_category}</td>
                  <td>{beautician.average_rating || 'N/A'}</td>
                  <td className="actions-cell">
                    <button 
                      className="action-button edit"
                      onClick={() => handleEdit(beautician)}
                    >
                      <Edit2 size={16} />
                    </button>
                    <button 
                      className="action-button delete"
                      onClick={() => handleDelete(beautician.id)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <BeauticianForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        beautician={editingBeautician}
        onSubmitSuccess={fetchBeauticians}
      />
    </div>
  );
};

export default BeauticianAdmin;