import React from 'react';
import SearchBar from '../searchbar/SearchBar';
import heroImage from '../../images/hero/hero.png';
import './Hero.css';

const Hero = () => {
  return (
    <div className="hero-wrapper">
      <div className="hero-background" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-overlay"></div>
      </div>
      <section className="hero">
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-8 col-xl-6">
              <div className="hero-content">
                <h1 className="display-4 fw-bold mb-4">
                  Trouvez votre esthéticienne idéale partout en France
                </h1>
                <p className="lead mb-4">
                  Planibeauty vous met en relation avec les meilleures esthéticiennes 
                  professionnelles près de chez vous.
                </p>
                <SearchBar />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;