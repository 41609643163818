import React, { useState, useRef, useEffect } from 'react';
import { MapPin, X } from 'lucide-react';
import { searchAddresses } from '../../data/searchUtils';
import Filters from '../../data/Filters'; // Correction du chemin d'importation
import './FiltersColumn.css';

const FiltersColumn = ({
  searchQuery,
  setSearchQuery,
  selectedServices,
  setSelectedServices,
  selectedPrices,
  setSelectedPrices,
  minimumRating,
  setMinimumRating,
  radius,
  setRadius,
  onLocationChange
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const wrapperRef = useRef(null);

  // Gestion de la recherche d'adresse
  const handleSearch = async (value) => {
    setSearchQuery(value);
    
    if (value.length < 2) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    try {
      setIsLoading(true);
      const suggestions = await searchAddresses(value);
      setSuggestions(suggestions);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Erreur lors de la recherche d\'adresses:', error);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour effacer la recherche
  const handleClearSearch = () => {
    setSearchQuery('');
    setSuggestions([]);
    setShowSuggestions(false);
    onLocationChange(null);
  };

  // Fermeture des suggestions au clic extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Sélection d'une adresse
  const handleAddressSelect = (address) => {
    setSearchQuery(address.value);
    onLocationChange({
      lat: address.coordinates[0],
      lng: address.coordinates[1]
    });
    setShowSuggestions(false);
  };

  return (
    <div className="filters-column">
      <div className="search-box-container" ref={wrapperRef}>
        <div className="search-box">
          <MapPin size={20} className={isLoading ? 'animate-spin' : ''} />
          <input
            type="text"
            placeholder="Entrez une adresse..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={() => searchQuery.length >= 2 && setShowSuggestions(true)}
          />
          {searchQuery && (
            <button 
              type="button"
              className="clear-button"
              onClick={handleClearSearch}
              aria-label="Effacer la recherche"
            >
              <X size={16} />
            </button>
          )}
        </div>

        {showSuggestions && (
          <div className="suggestions-dropdown">
            {isLoading ? (
              <div className="suggestion-loading">
                <div className="loading-spinner"></div>
                <span>Recherche en cours...</span>
              </div>
            ) : suggestions.length > 0 ? (
              suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleAddressSelect(suggestion)}
                >
                  <div className="suggestion-content">
                    <span className="suggestion-text">{suggestion.text}</span>
                    {suggestion.subtext && (
                      <span className="suggestion-subtext">
                        {suggestion.subtext}
                      </span>
                    )}
                  </div>
                  <span className={`suggestion-type ${suggestion.type}`}>
                    {suggestion.type}
                  </span>
                </div>
              ))
            ) : (
              <div className="no-results">Aucun résultat trouvé</div>
            )}
          </div>
        )}
      </div>

      <div className="filters-scroll">
        <Filters
          selectedServices={selectedServices}
          onServicesChange={setSelectedServices}
          selectedPrices={selectedPrices}
          onPricesChange={setSelectedPrices}
          minimumRating={minimumRating}
          onRatingChange={setMinimumRating}
          radius={radius}
          onRadiusChange={setRadius}
        />
      </div>
    </div>
  );
};

export default FiltersColumn;