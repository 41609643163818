import React from 'react';
import { Star } from 'lucide-react';
import { serviceCategories, priceRanges } from './beauticiansData';
import './Filters.css';

const Filters = ({ 
  selectedServices, 
  onServicesChange,
  selectedPrices,
  onPricesChange,
  minimumRating,
  onRatingChange,
  radius,
  onRadiusChange
}) => {
  const ratings = [5, 4, 3, 2, 1];

  return (
    <div className="filters-container">
      {/* Services */}
      <div className="filter-section">
        <h3>Services</h3>
        <div className="services-grid">
          {serviceCategories.map((service) => (
            <label key={service.id} className="service-checkbox">
              <input
                type="checkbox"
                checked={selectedServices.includes(service.id)}
                onChange={() => {
                  const updated = selectedServices.includes(service.id)
                    ? selectedServices.filter(id => id !== service.id)
                    : [...selectedServices, service.id];
                  onServicesChange(updated);
                }}
              />
              <span>{service.name}</span>
              <span className="count">{service.count}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Prix */}
      <div className="filter-section">
        <h3>Budget</h3>
        {priceRanges.map((range) => (
          <label key={range.id} className="price-checkbox">
            <input
              type="checkbox"
              checked={selectedPrices.includes(range.value)}
              onChange={() => {
                const updated = selectedPrices.includes(range.value)
                  ? selectedPrices.filter(price => price !== range.value)
                  : [...selectedPrices, range.value];
                onPricesChange(updated);
              }}
            />
            <span>{range.label}</span>
            <span className="count">{range.count}</span>
          </label>
        ))}
      </div>

      {/* Note minimum */}
      <div className="filter-section">
        <h3>Note minimum</h3>
        <div className="rating-filter">
          {ratings.map((rating) => (
            <div 
              key={rating}
              className="rating-option"
              onClick={() => onRatingChange(rating)}
            >
              <label className="rating-label">
                <input
                  type="radio"
                  value={rating}
                  checked={minimumRating === rating}
                  onChange={() => onRatingChange(rating)}
                />
                {[...Array(5)].map((_, index) => (
                  <Star
                    key={index}
                    size={16}
                    className={`star ${index < rating ? 'filled' : ''}`}
                  />
                ))}
                <span className="rating-text">
                  {rating} étoile{rating > 1 ? 's' : ''} et plus
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Rayon de recherche */}
      <div className="filter-section">
        <h3>Rayon de recherche</h3>
        <div className="radius-slider">
          <input
            type="range"
            min="1"
            max="20"
            step="1"
            value={radius / 1000}
            onChange={(e) => onRadiusChange(parseInt(e.target.value) * 1000)}
          />
          <span className="radius-value">{radius / 1000} km</span>
        </div>
      </div>
    </div>
  );
};

export default Filters;