import "./UserData.css"

import { useId } from "react"
import { FaEllipsisVertical, FaEye, FaPencil, FaTrash } from "react-icons/fa6"

const UserData = ({ data }) => {
    const id = useId()

    return <>
        <section className="user-data relative mt-2 py-3 border shadow-sm hover:bg-gray-100 duration-300">
            <span className="text-center col-span-2">{data["id"]}</span>
            <span className="text-center col-span-4">{data["nom_prenom"]}</span>
            <span className="text-center col-span-3">{data["adresse_postale"]}</span>
            <span className="text-center col-span-3">{data["code_postal"]}</span>
            <span className="text-center col-span-2">{data["ville"]}</span>
            <span className="text-center col-span-4">{data["numero_telephone"]}</span>
            <span className="text-center col-span-4">{data["prestation_proposee"]}</span>
            <span className="text-center col-span-4">{data["derniere_connexion"]}</span>
            <span className="end text-center col-span-3 border-r-0">{data["date_creation"]}</span>
            
            <label htmlFor={id} className="me-1 flex items-center justify-center text-gray-600 cursor-pointer">
                <FaEllipsisVertical />
            </label>
            <input type="radio" name="show-menu" id={id} hidden/>
            <ul className="ms-auto peer-checked:flex data-menu absolute p-3 flex flex-col gap-2 bg-white border rounded shadow bottom-0">
                <li>
                    <button className="flex items-center gap-2"><FaPencil />Modifier</button>
                </li>
                <li>
                    <button className="flex items-center gap-2"><FaTrash />Supprimer</button>
                </li>
                <li>
                    <button className="flex items-center gap-2"><FaEye />Voir les informations du site</button>
                </li>
            </ul>
        </section>
    </>
}

export default UserData