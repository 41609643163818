import React, { useState, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import BeauticianCard from '../beautician/BeauticianCard';
import './ResultsColumn.css';

const ITEMS_PER_PAGE = 10;

const ResultsColumn = ({ beauticians }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const columnRef = useRef(null);
  const totalPages = Math.ceil(beauticians.length / ITEMS_PER_PAGE);

  const getCurrentPageItems = () => {
    const start = currentPage * ITEMS_PER_PAGE;
    return beauticians.slice(start, start + ITEMS_PER_PAGE);
  };

  const handlePageChange = (newPage) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(newPage);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 50);
    }, 300);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <div className="results-column" ref={columnRef}>
      <div className="results-header">
        <h2>{beauticians.length} esthéticiennes trouvées</h2>
      </div>
      
      <div className={`beauticians-grid ${isTransitioning ? 'transitioning' : ''}`}>
        {getCurrentPageItems().map((beautician) => (
          <div key={beautician.id} className="beautician-card-wrapper">
            <BeauticianCard beautician={beautician} />
          </div>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="carousel-controls">
          <button 
            className="carousel-button"
            onClick={handlePrevPage}
            disabled={currentPage === 0}
            aria-label="Page précédente"
          >
            <ChevronLeft size={24} />
          </button>
          
          <div className="carousel-indicators">
            {Array.from({ length: totalPages }).map((_, index) => (
              <span
                key={index}
                className={`carousel-dot ${index === currentPage ? 'active' : ''}`}
                onClick={() => handlePageChange(index)}
                role="button"
                tabIndex={0}
                aria-label={`Page ${index + 1}`}
              />
            ))}
          </div>
          
          <button 
            className="carousel-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
            aria-label="Page suivante"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ResultsColumn;