import React, { useState, useEffect } from 'react';
import { 
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useParams,
  Outlet,
  createRoutesFromElements,
  Route
} from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './pages/home/Home';
import Concept from './pages/concept/Concept';
import Search from './pages/search/Search';
import Contact from './pages/contact/Contact';
import BeauticianProfile from './pages/beautician/BeauticianProfile';
import BeauticianAdmin from './components/admin/BeauticianAdmin';
import NotFound from './pages/notFound/NotFound';
import Loader from './components/loader/Loader';
import { beauticians } from './data/beauticiansData';
import { formatProfilePath } from './utils/domainUtils';
import './App.css';
import Dashboard from './pages/dashboard/Dashboard';
import Users from './pages/dashboard/user/Users';

// Protection des routes admin
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('token');
  const userRole = localStorage.getItem('userRole');

  if (!isAuthenticated || userRole !== 'admin') {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Composant Layout avec Outlet pour le rendu des routes enfants
const LayoutWrapper = () => (
  <Layout>
    <Outlet />
  </Layout>
);

// Composant pour gérer les profils des esthéticiennes
const BeauticianProfileWrapper = () => {
  const { profilePath } = useParams();
  
  const beautician = beauticians.find(b => 
    formatProfilePath(b.name) === profilePath
  );

  if (!beautician) {
    return <Navigate to="/404" replace />;
  }

  return <BeauticianProfile beautician={beautician} />;
};

// Wrapper pour les routes admin
const AdminRoute = () => (
  <ProtectedRoute>
    <BeauticianAdmin />
  </ProtectedRoute>
);

// Définition des routes avec la syntaxe JSX
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="" element={<LayoutWrapper />}>
        <Route index element={<Home />} />
        <Route path="search" element={<Search />} />
        <Route path="concept" element={<Concept />} />
        <Route path="contact" element={<Contact />} />
        <Route path="admin">
          <Route path="beauticians" element={<AdminRoute />} />
        </Route>
        <Route path="404" element={<NotFound />} />
        <Route path="beautician/:profilePath" element={<BeauticianProfileWrapper />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
      <Route path="dashboard" element={<Dashboard />}>
        <Route path="" element={<Users />}></Route>
      </Route>
    </Route>
  ),
  {
    future: {
      v7_startTransition: true
    }
  }
);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const minLoadingTime = 1000;
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, minLoadingTime);

    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </>
  );
}

export default App;