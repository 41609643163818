import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import axios from 'axios';
import { beauticians, serviceCategories } from '../../data/beauticiansData';
import './SearchBar.css';

const SearchBar = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const wrapperRef = useRef(null);

  const axiosInstance = useMemo(
    () => axios.create({ timeout: 5000 }),
    []
  );

  // Fonction de navigation vers la page de recherche
  const handleSearch = (searchValue, type, coordinates) => {
    const searchParams = new URLSearchParams();
    
    if (coordinates) {
      searchParams.append('lat', coordinates.lat);
      searchParams.append('lng', coordinates.lng);
    }
    
    if (type === 'spécialité') {
      searchParams.append('service', searchValue);
    } else {
      searchParams.append('q', searchValue);
    }

    navigate({
      pathname: '/search',
      search: searchParams.toString()
    });
  };

  // Effect pour la recherche
  useEffect(() => {
    let isActive = true;
    const fetchTimeout = 3000;

    const fetchSuggestions = async () => {
      if (searchTerm.length < 2) {
        setSuggestions([]);
        setShowSuggestions(false);
        return;
      }

      setIsLoading(true);

      const getLocalResults = () => {
        const normalizedQuery = searchTerm.toLowerCase();
        
        const beauticianResults = beauticians
          .filter(b => 
            b.name.toLowerCase().includes(normalizedQuery) || 
            b.city.toLowerCase().includes(normalizedQuery) ||
            b.specialties.some(s => s.toLowerCase().includes(normalizedQuery))
          )
          .map(b => ({
            text: b.name,
            type: 'esthéticienne',
            subtext: `${b.city} - ${b.specialties.join(', ')}`
          }));

        const specialtyResults = serviceCategories
          .filter(cat => cat.name.toLowerCase().includes(normalizedQuery))
          .map(cat => ({
            text: cat.name,
            type: 'spécialité',
            subtext: `${cat.count} esthéticiennes`
          }));

        return [...beauticianResults, ...specialtyResults];
      };

      const getLocationResults = async () => {
        try {
          const [citiesResponse, addressesResponse] = await Promise.all([
            axiosInstance.get(
              `https://geo.api.gouv.fr/communes?nom=${encodeURIComponent(searchTerm)}&fields=nom,code,codeDepartement,departement,population&boost=population&limit=3`
            ),
            axiosInstance.get(
              `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(searchTerm)}&type=street&limit=2`
            )
          ]);

          const cities = citiesResponse.data.map(city => ({
            text: city.nom,
            type: 'ville',
            subtext: `${city.codeDepartement} - ${city.departement.nom}`,
            cityCode: city.code,
            coordinates: null // À implémenter si besoin
          }));

          const addresses = addressesResponse.data.features.map(feature => ({
            text: feature.properties.name,
            type: 'quartier',
            subtext: `${feature.properties.city} (${feature.properties.postcode})`,
            cityCode: feature.properties.citycode,
            coordinates: {
              lat: feature.geometry.coordinates[1],
              lng: feature.geometry.coordinates[0]
            }
          }));

          return [...cities, ...addresses];
        } catch (error) {
          console.error('Erreur lors de la recherche de lieux:', error);
          return [];
        }
      };

      try {
        const locationPromise = getLocationResults();
        const timeoutPromise = new Promise((_, reject) => 
          setTimeout(() => reject(new Error('Timeout')), fetchTimeout)
        );

        const localResults = getLocalResults();
        const locationResults = await Promise.race([locationPromise, timeoutPromise]);

        if (!isActive) return;

        const allResults = [...locationResults, ...localResults].slice(0, 8);
        setSuggestions(allResults);
        setShowSuggestions(allResults.length > 0);
      } catch (error) {
        console.error('Erreur:', error);
        if (isActive) {
          const localResults = getLocalResults();
          setSuggestions(localResults);
          setShowSuggestions(localResults.length > 0);
        }
      } finally {
        if (isActive) {
          setIsLoading(false);
        }
      }
    };

    const timeoutId = setTimeout(fetchSuggestions, 300);
    return () => {
      isActive = false;
      clearTimeout(timeoutId);
    };
  }, [searchTerm, axiosInstance]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="search-bar-wrapper" ref={wrapperRef}>
      <div className="search-bar-container">
        <div className="search-input-group">
          <input
            type="text"
            className="search-input"
            placeholder="Ville, quartier, spécialité ou nom..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => searchTerm.length >= 2 && setShowSuggestions(true)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && searchTerm.length >= 2) {
                handleSearch(searchTerm, 'text');
              }
            }}
          />
          <button 
            className="search-button" 
            type="button"
            onClick={() => searchTerm.length >= 2 && handleSearch(searchTerm, 'text')}
          >
            <Search size={24} />
          </button>
        </div>
        
        {showSuggestions && (
          <div className="suggestions-dropdown">
            {isLoading ? (
              <div className="suggestion-loading">
                <div className="loading-spinner"></div>
                <span>Recherche en cours...</span>
              </div>
            ) : suggestions.length > 0 ? (
              suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => {
                    handleSearch(suggestion.text, suggestion.type, suggestion.coordinates);
                    setSearchTerm(suggestion.text);
                    setShowSuggestions(false);
                  }}
                >
                  <div className="suggestion-content">
                    <span className="suggestion-text">{suggestion.text}</span>
                    {suggestion.subtext && (
                      <span className="suggestion-subtext">
                        {suggestion.subtext}
                      </span>
                    )}
                  </div>
                  <span className={`suggestion-type ${suggestion.type}`}>
                    {suggestion.type}
                  </span>
                </div>
              ))
            ) : (
              <div className="suggestion-empty">Aucun résultat trouvé</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;