import React, { useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { FaCamera, FaX } from 'react-icons/fa6'

const UserCreation = ({ close }) => {
    const [nom, setNom] = useState("")
    const [prenom, setPrenom] = useState("")
    const [masquerNomPrenom, setMasquerNomPrenom] = useState(false)
    const [nomCommercial, setNomCommercial] = useState("")
    const [adresse, setAdresse] = useState("")
    const [email, setEmail] = useState("")
    const [numero, setNumero] = useState("")
    const [motDePasse, setMotDePasse] = useState("")
    const [confirmMotDePasse, setConfirmMotDePasse] = useState("")
    const [photoDeProfil, setPhotoDeProfil] = useState("")

    const valider = () => {
        console.log(nom, prenom, masquerNomPrenom, nomCommercial, adresse, email, numero, motDePasse, confirmMotDePasse)
    }

    return (
        <div className="absolute w-screen h-screen flex items-center justify-center bg-gray-800 bg-opacity-80">
            <form onSubmit={(e) => e.preventDefault()} className="relative w-2/5 p-4 flex flex-col gap-2 bg-white border rounded shadow">
                <button className="absolute right-4 top-4" onClick={() => close(false)}><FaX /></button>

                <h1 className="text-center text-gray-800 text-2xl">Créer un utilisateur</h1>

                <div className="px-3 p-2 flex gap-2 border rounded">
                    <input type="text" className="w-full outline-none" placeholder="Nom" value={nom} onChange={(e) => setNom(e.target.value)}/>
                </div>

                <div className="px-3 py-2 flex gap-2 border rounded">
                    <input type="text" className="w-full outline-none" placeholder="Prénom(s)" value={prenom} onChange={(e) => setPrenom(e.target.value)}/>
                </div>

                <div className="ms-2 flex gap-2">
                    <input type="checkbox" value={masquerNomPrenom} onChange={(e) => setMasquerNomPrenom(e.target.value)}/>
                    <label htmlFor="masquer-nom-complet">Ne pas rendre visible le nom et prénom</label>
                </div>

                <div className="px-3 py-2 flex gap-2 border rounded">
                    <input type="text" className="w-full outline-none" placeholder="Nom commercial" value={nomCommercial} onChange={(e) => setNomCommercial(e.target.value)}/>
                </div>

                <div className="px-3 py-2 flex gap-2 border rounded">
                    <input type="text" className="w-full outline-none" placeholder="Adresse postal" value={adresse} onChange={(e) => setAdresse(e.target.value)}/>
                </div>

                <div className="px-3 py-2 flex gap-2 border rounded">
                    <input type="text" className="w-full outline-none" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                
                <div className="px-3 py-2 flex gap-2 border rounded">
                    <input type="text" className="w-full outline-none" placeholder="Numéro de téléphone" value={numero} onChange={(e) => setNumero(e.target.value)}/>
                </div>
                
                <div className="px-3 py-2 flex gap-2 border rounded">
                    <input type="password" className="w-full outline-none" placeholder="Mot de passe" value={motDePasse} onChange={(e) => setMotDePasse(e.target.value)}/>
                </div>

                <div className="px-3 py-2 flex gap-2 border rounded">
                    <input type="password" className="w-full outline-none" placeholder="Vérification mot de passe" value={confirmMotDePasse} onChange={(e) => setConfirmMotDePasse(e.target.value)}/>
                </div>

                <div className="flex gap-4 justify-between mt-2">
                    <button className="flex gap-2 items-center"><FaCamera /> Ajouter une photo de profil</button>
                    <button onClick={valider} className="px-3 py-2 flex gap-2 items-center text-white bg-green-600 rounded"><FaSave /> Enregister</button>
                </div>
            </form>

            {/* <div className="p-3 absolute bottom-3 left-[50%] -translate-x-[50%] bg-white rounded border">
                Les mots de passe ne correspond pas
            </div> */}
        </div>
    )
}

export default UserCreation