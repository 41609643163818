import React from 'react';
import { Plus } from 'lucide-react';
import './ServicesList.css';

const ServiceCategory = ({ title, services }) => {
  return (
    <div className="service-category">
      <h2>{title}</h2>
      <div className="service-list">
        {services.map((service, index) => (
          <div key={index} className="service-item">
            <div className="service-info">
              <h3 className="service-name">{service.name}</h3>
              <p className="service-description">{service.description}</p>
            </div>
            <div className="service-details">
              <div className="price-duration">
                <span className="price">{service.price}€</span>
                <span className="duration">{service.duration} min</span>
              </div>
              <button className="add-button">
                <Plus size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ServicesList = () => {
  const services = [
    {
      category: "Soins du Visage",
      items: [
        {
          name: "Soin Hydratant",
          description: "Nettoyage, gommage et masque hydratant",
          price: 45,
          duration: 45
        },
        {
          name: "Soin Anti-âge",
          description: "Traitement complet anti-rides avec sérum spécifique",
          price: 65,
          duration: 60
        },
        {
          name: "Soin Purifiant",
          description: "Nettoyage en profondeur, extraction des comédons",
          price: 55,
          duration: 50
        }
      ]
    },
    {
      category: "Épilation",
      items: [
        {
          name: "Épilation Jambes Complètes",
          description: "Épilation à la cire des jambes entières",
          price: 35,
          duration: 40
        },
        {
          name: "Maillot Brésilien",
          description: "Épilation à la cire tiède",
          price: 25,
          duration: 30
        },
        {
          name: "Forfait Visage",
          description: "Sourcils, lèvre supérieure et menton",
          price: 20,
          duration: 25
        }
      ]
    },
    {
      category: "Soins des Mains et des Pieds",
      items: [
        {
          name: "Manucure Complète",
          description: "Soin des cuticules, limage et pose de vernis",
          price: 35,
          duration: 45
        },
        {
          name: "Pédicure Spa",
          description: "Bain de pieds, gommage et massage",
          price: 45,
          duration: 50
        },
        {
          name: "Pose Semi-permanent",
          description: "Pose de vernis semi-permanent mains ou pieds",
          price: 40,
          duration: 45
        }
      ]
    }
  ];

  return (
    <div className="services-container">
      {services.map((category, index) => (
        <ServiceCategory
          key={index}
          title={category.category}
          services={category.items}
        />
      ))}
    </div>
  );
};

export default ServicesList;