// Fonction pour formater le nom en URL-friendly
export const formatProfilePath = (name) => {
  return name
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Enlève les accents
    .replace(/[^a-z0-9]/g, '-') // Remplace les caractères spéciaux par des tirets
    .replace(/-+/g, '-') // Évite les tirets multiples
    .replace(/^-+|-+$/g, '') // Enlève les tirets au début et à la fin
    .trim();
};

// Fonction pour obtenir l'URL du profil
export const getProfileUrl = (beautician) => {
  const path = formatProfilePath(beautician.name);
  return `/beautician/${path}`; // Ajout du prefix /beautician/
};