// Imports des images
import beautician1 from '../images/beautician/beautician (1).png';
import beautician2 from '../images/beautician/beautician (2).png';
import beautician3 from '../images/beautician/beautician (3).png';
import beautician4 from '../images/beautician/beautician (4).png';
import beautician5 from '../images/beautician/beautician (5).png';
import beautician6 from '../images/beautician/beautician (6).png';
import beautician7 from '../images/beautician/beautician (7).png';
import beautician8 from '../images/beautician/beautician (8).png';
import beautician9 from '../images/beautician/beautician (9).png';
import beautician10 from '../images/beautician/beautician (10).png';
import beautician11 from '../images/beautician/beautician (11).png';

const calculateServiceCounts = (beauticiansList) => {
  const counts = {
    'Massage': 0,
    'Soins du visage': 0,
    'Manucure': 0,
    'Pédicure': 0,
    'Maquillage': 0,
    'Extension de cils': 0,
    'Maquillage permanent': 0,
    'Soins spa': 0,
    'Soins bio': 0
  };

  beauticiansList.forEach(beautician => {
    beautician.specialties.forEach(specialty => {
      if (counts.hasOwnProperty(specialty)) {
        counts[specialty]++;
      }
    });
  });

  // Pour Manucure/Pédicure, nous comptons les esthéticiennes qui font l'un ou l'autre
  const nailsCount = beauticiansList.filter(b => 
    b.specialties.includes('Manucure') || b.specialties.includes('Pédicure')
  ).length;

  return { ...counts, nailsService: nailsCount };
};

export const beauticians = [
  {
    id: 1,
    name: 'Sophie Martin',
    city: 'Paris',
    district: 'Le Marais',
    zipCode: '75004',
    rating: 4.5,
    image: beautician1,
    specialties: ['Massage', 'Soins du visage', 'Manucure'],
    price_range: '€€',
    coordinates: { lat: 48.8566, lng: 2.3522 }
  },
  {
    id: 2,
    name: 'Marie Dubois',
    city: 'Lyon',
    district: 'Presqu\'île',
    zipCode: '69002',
    rating: 5,
    image: beautician2,
    specialties: ['Massage', 'Soins du visage', 'Soins spa'],
    price_range: '€€€',
    coordinates: { lat: 45.7578, lng: 4.8320 }
  },
  {
    id: 3,
    name: 'Julie Bernard',
    city: 'Marseille',
    district: 'Vieux-Port',
    zipCode: '13001',
    rating: 4,
    image: beautician3,
    specialties: ['Maquillage permanent', 'Extension de cils', 'Maquillage'],
    price_range: '€€€',
    coordinates: { lat: 43.2965, lng: 5.3698 }
  },
  {
    id: 4,
    name: 'Emma Lambert',
    city: 'Bordeaux',
    district: 'Saint-Pierre',
    zipCode: '33000',
    rating: 4.5,
    image: beautician4,
    specialties: ['Massage', 'Soins spa', 'Soins bio'],
    price_range: '€€',
    coordinates: { lat: 44.8378, lng: -0.5792 }
  },
  {
    id: 5,
    name: 'Laura Petit',
    city: 'Lille',
    district: 'Vieux-Lille',
    zipCode: '59000',
    rating: 5,
    image: beautician5,
    specialties: ['Manucure', 'Pédicure', 'Soins du visage'],
    price_range: '€€',
    coordinates: { lat: 50.6365, lng: 3.0635 }
  },
  {
    id: 6,
    name: 'Claire Moreau',
    city: 'Nantes',
    district: 'Centre-ville',
    zipCode: '44000',
    rating: 4.5,
    image: beautician6,
    specialties: ['Massage', 'Soins bio', 'Soins du visage'],
    price_range: '€€',
    coordinates: { lat: 47.2184, lng: -1.5536 }
  },
  {
    id: 7,
    name: 'Alice Roux',
    city: 'Toulouse',
    district: 'Capitole',
    zipCode: '31000',
    rating: 4,
    image: beautician7,
    specialties: ['Maquillage', 'Extension de cils', 'Soins du visage'],
    price_range: '€',
    coordinates: { lat: 43.6045, lng: 1.4442 }
  },
  {
    id: 8,
    name: 'Sarah Leroy',
    city: 'Nice',
    district: 'Vieux Nice',
    zipCode: '06300',
    rating: 4.5,
    image: beautician8,
    specialties: ['Massage', 'Soins spa', 'Manucure'],
    price_range: '€€€',
    coordinates: { lat: 43.6960, lng: 7.2659 }
  },
  {
    id: 9,
    name: 'Léa Martin',
    city: 'Rennes',
    district: 'Centre Historique',
    zipCode: '35000',
    rating: 5,
    image: beautician9,
    specialties: ['Massage', 'Soins bio', 'Pédicure'],
    price_range: '€€',
    coordinates: { lat: 48.1147, lng: -1.6794 }
  },
  {
    id: 10,
    name: 'Chloé Simon',
    city: 'Strasbourg',
    district: 'Grande Île',
    zipCode: '67000',
    rating: 4.5,
    image: beautician10,
    specialties: ['Soins du visage', 'Massage', 'Pédicure'],
    price_range: '€€',
    coordinates: { lat: 48.5734, lng: 7.7521 }
  },
  {
    id: 11,
    name: 'Audrey Lambert',
    city: 'Montpellier',
    district: 'Écusson',
    zipCode: '34000',
    rating: 4.5,
    image: beautician11,
    specialties: ['Soins bio', 'Extension de cils', 'Soins du visage'],
    price_range: '€€',
    coordinates: { lat: 43.6108, lng: 3.8767 }
  }
];
const serviceCounts = calculateServiceCounts(beauticians);

export const serviceCategories = [
  { 
    id: 'massage', 
    name: 'Massage', 
    value: 'Massage', 
    count: serviceCounts['Massage']
  },
  { 
    id: 'facial', 
    name: 'Soins du visage', 
    value: 'Soins du visage', 
    count: serviceCounts['Soins du visage']
  },
  { 
    id: 'nails', 
    name: 'Manucure/Pédicure', 
    value: ['Manucure', 'Pédicure'], 
    count: serviceCounts.nailsService
  },
  { 
    id: 'makeup', 
    name: 'Maquillage', 
    value: 'Maquillage', 
    count: serviceCounts['Maquillage']
  },
  { 
    id: 'eyelashes', 
    name: 'Extension de cils', 
    value: 'Extension de cils', 
    count: serviceCounts['Extension de cils']
  },
  { 
    id: 'spa', 
    name: 'Soins spa', 
    value: 'Soins spa', 
    count: serviceCounts['Soins spa']
  },
  { 
    id: 'permanent', 
    name: 'Maquillage permanent', 
    value: 'Maquillage permanent', 
    count: serviceCounts['Maquillage permanent']
  },
  { 
    id: 'bio', 
    name: 'Soins bio', 
    value: 'Soins bio', 
    count: serviceCounts['Soins bio']
  }
];

// Calculer les counts des gammes de prix
const calculatePriceRangeCounts = (beauticiansList) => {
  const counts = {
    '€': 0,
    '€€': 0,
    '€€€': 0
  };

  beauticiansList.forEach(beautician => {
    counts[beautician.price_range]++;
  });

  return counts;
};

const priceRangeCounts = calculatePriceRangeCounts(beauticians);

export const priceRanges = [
  { 
    id: 1, 
    label: '€ (Économique)', 
    value: '€', 
    count: priceRangeCounts['€']
  },
  { 
    id: 2, 
    label: '€€ (Modéré)', 
    value: '€€', 
    count: priceRangeCounts['€€']
  },
  { 
    id: 3, 
    label: '€€€ (Premium)', 
    value: '€€€', 
    count: priceRangeCounts['€€€']
  }
];
