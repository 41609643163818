// src/api/user.api.js
const API_URL = process.env.REACT_APP_API_URL;

const userApi = {
    register: async (userData) => {
        try {
            const response = await fetch(`${API_URL}/users/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de l\'inscription');
        }
    },

    login: async (credentials) => {
        try {
            const response = await fetch(`${API_URL}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la connexion');
        }
    },

    getProfile: async (token) => {
        try {
            const response = await fetch(`${API_URL}/users/profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du profil');
        }
    },

    updateProfile: async (token, userData) => {
        try {
            const response = await fetch(`${API_URL}/users/profile`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du profil');
        }
    },

    changePassword: async (token, passwordData) => {
        try {
            const response = await fetch(`${API_URL}/users/change-password`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(passwordData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors du changement de mot de passe');
        }
    },

    deleteAccount: async (token) => {
        try {
            const response = await fetch(`${API_URL}/users/delete`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression du compte');
        }
    },

    createBeauticianUser: async (token, userData) => {
        try {
            const response = await fetch(`${API_URL}/users/create-beautician`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création de l\'utilisateur beautician');
        }
    },

    // Autres méthodes API selon vos besoins...
};

export default userApi;