import React, { useState, useEffect } from 'react';
import './Header.css';
import logo from '../../assets/images/3.png';
import LoginModal from '../loginModal/LoginModal';
import SignupModal from '../signupModal/SignupModal';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Vérifier si l'utilisateur est connecté au chargement
    const token = localStorage.getItem('userToken');
    if (token) {
      setIsLoggedIn(true);
      // Optionnel : Récupérer le nom de l'utilisateur du localStorage
      const storedUserName = localStorage.getItem('userName');
      if (storedUserName) {
        setUserName(storedUserName);
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLoginClick = (e) => {
    e.preventDefault();
    setIsSignupModalOpen(false);
    setIsLoginModalOpen(true);
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    setIsLoginModalOpen(false);
    setIsSignupModalOpen(true);
  };

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const handleLogout = () => {
    // Supprimer les informations d'authentification
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    localStorage.removeItem('userName');
    setIsLoggedIn(false);
    setUserName('');
    // Recharger la page ou rediriger vers la page d'accueil
    window.location.href = '/';
  };

  const handleLoginSuccess = (userData) => {
    setIsLoggedIn(true);
    if (userData.first_name) {
      setUserName(userData.first_name);
      localStorage.setItem('userName', userData.first_name);
    }
  };

  return (
    <>
      <nav className={`navbar navbar-expand-md fixed-top ${isScrolled ? 'scrolled' : ''}`}>
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Planibeauty" className="navbar-logo" />
          </a>

          <button 
            className="navbar-toggler" 
            type="button" 
            onClick={handleNavCollapse}
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarContent">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="/concept">Le concept</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/search">Trouver une esthéticienne</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/devenir-partenaire">
                  Rejoindre nos expertes beauté
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/reserver">
                  Réserver un soin
                </a>
              </li>
              <li className="nav-item">
                {isLoggedIn ? (
                  <button 
                    className="nav-link login-button" 
                    onClick={handleLogout}
                  >
                    Déconnexion {userName && `(${userName})`}
                  </button>
                ) : (
                  <a 
                    className="nav-link login-button" 
                    href="/connexion" 
                    onClick={handleLoginClick}
                  >
                    Connexion
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <LoginModal 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)}
        onSignupClick={handleSignupClick}
        onLoginSuccess={handleLoginSuccess}
      />

      <SignupModal 
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
        onLoginClick={handleLoginClick}
      />
    </>
  );
};

export default Header;