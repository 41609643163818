import React from 'react';
import { Link } from 'react-router-dom';
import { Construction, ArrowLeft } from 'lucide-react';
import './UnderConstruction.css';

const UnderConstruction = ({ pageName }) => {
  return (
    <div className="under-construction">
      <div className="container">
        <div className="construction-content">
          <Construction size={64} className="construction-icon" />
          <h1>Page {pageName} en construction</h1>
          <p className="construction-message">
            Nous travaillons actuellement sur cette page pour vous offrir la meilleure expérience possible.
            Revenez bientôt pour découvrir son contenu !
          </p>
          <div className="construction-progress">
            <div className="progress-bar">
              <div className="progress-fill"></div>
            </div>
            <span>En cours de développement</span>
          </div>
          <Link to="/" className="back-home">
            <ArrowLeft size={20} />
            Retour à l'accueil
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;