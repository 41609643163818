import React, { useState } from 'react';
import './SignupModal.css';
import logo from '../../assets/images/8.png';
import userApi from '../../api/user.api';

const SignupModal = ({ isOpen, onClose, onLoginClick }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    password: '',
    confirmPassword: ''
  });
  
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Fonction pour déterminer l'URL de base selon l'environnement
  const getBaseUrl = () => {
    const currentUrl = window.location.origin;
    return currentUrl.includes('localhost') ? 'http://localhost:3000' : 'https://planibeauty.fr';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (name === 'confirmPassword' || name === 'password') {
      if (name === 'confirmPassword' && value !== formData.password) {
        setPasswordError('Les mots de passe ne correspondent pas');
      } else if (name === 'password' && value !== formData.confirmPassword && formData.confirmPassword) {
        setPasswordError('Les mots de passe ne correspondent pas');
      } else {
        setPasswordError('');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Les mots de passe ne correspondent pas');
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      // Préparer les données pour l'API
      const signupData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        password: formData.password,
        user_type: 'client' // Forcer le type client
      };

      const response = await userApi.register(signupData);

      if (response.user) {
        // Réinitialiser le formulaire
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: '',
          password: '',
          confirmPassword: ''
        });

        // Rediriger vers la page d'accueil (ne pas rediriger vers le dashboard car c'est un client)
        const baseUrl = getBaseUrl();
        window.location.href = baseUrl;
        onClose();
      } else {
        setError(response.message || 'Une erreur est survenue lors de l\'inscription');
      }
    } catch (error) {
      setError(error.message || 'Une erreur est survenue lors de l\'inscription');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className={`modal-content signup-content ${isLoading ? 'loading' : ''}`}>
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="modal-logo">
          <img src={logo} alt="Logo" />
        </div>

        <h2 className="modal-title">Créer un compte</h2>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="signup-form">
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Nom"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Prénom"
                required
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Adresse email"
              required
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Téléphone"
              required
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Mot de passe"
              required
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirmer le mot de passe"
              required
              disabled={isLoading}
            />
            {passwordError && <span className="error-message">{passwordError}</span>}
          </div>

          <button 
            type="submit" 
            className="signup-button"
            disabled={isLoading}
          >
            {isLoading ? 'Inscription en cours...' : 'S\'inscrire'}
          </button>

          <div className="login-link">
            Déjà membre ? 
            <button 
              type="button" 
              onClick={onLoginClick}
              className="link-button"
              disabled={isLoading}
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupModal;