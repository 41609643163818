import React, { useState, useEffect } from 'react';
import beauticianApi from '../../api/beautician.api';
import { X, Upload } from 'lucide-react';
import './BeauticianForm.css';

const BeauticianForm = ({ isOpen, onClose, beautician, onSubmitSuccess }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    description: '',
    budget_category: 'standard',
    profile_photo: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (beautician) {
      setFormData({
        first_name: beautician.first_name || '',
        last_name: beautician.last_name || '',
        email: beautician.email || '',
        phone: beautician.phone || '',
        address: beautician.address || '',
        description: beautician.description || '',
        budget_category: beautician.budget_category || 'standard',
        profile_photo: beautician.profile_photo || '',
      });
    } else {
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        description: '',
        budget_category: 'standard',
        profile_photo: '',
      });
    }
  }, [beautician]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = 'Le prénom est requis';
    if (!formData.last_name) newErrors.last_name = 'Le nom est requis';
    if (!formData.email) newErrors.email = 'L\'email est requis';
    if (!formData.address) newErrors.address = 'L\'adresse est requise';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (beautician) {
        await beauticianApi.updateBeautician(token, beautician.id, formData);
      } else {
        await beauticianApi.createBeautician(token, formData);
      }
      
      onSubmitSuccess();
      onClose();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="beautician-form-modal">
        <button className="modal-close" onClick={onClose}>
          <X size={24} />
        </button>

        <h2>{beautician ? 'Modifier l\'esthéticienne' : 'Ajouter une esthéticienne'}</h2>

        <form onSubmit={handleSubmit} className="beautician-form">
          <div className="form-grid">
            <div className="form-group">
              <label>Prénom</label>
              <input
                type="text"
                value={formData.first_name}
                onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                className={errors.first_name ? 'error' : ''}
              />
              {errors.first_name && <span className="error-message">{errors.first_name}</span>}
            </div>

            <div className="form-group">
              <label>Nom</label>
              <input
                type="text"
                value={formData.last_name}
                onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                className={errors.last_name ? 'error' : ''}
              />
              {errors.last_name && <span className="error-message">{errors.last_name}</span>}
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div className="form-group">
              <label>Téléphone</label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({...formData, phone: e.target.value})}
              />
            </div>

            <div className="form-group full-width">
              <label>Adresse</label>
              <input
                type="text"
                value={formData.address}
                onChange={(e) => setFormData({...formData, address: e.target.value})}
                className={errors.address ? 'error' : ''}
              />
              {errors.address && <span className="error-message">{errors.address}</span>}
            </div>

            <div className="form-group">
              <label>Catégorie de budget</label>
              <select
                value={formData.budget_category}
                onChange={(e) => setFormData({...formData, budget_category: e.target.value})}
              >
                <option value="économique">Économique</option>
                <option value="standard">Standard</option>
                <option value="premium">Premium</option>
                <option value="luxe">Luxe</option>
              </select>
            </div>

            <div className="form-group full-width">
              <label>Description</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                rows="4"
              />
            </div>

            <div className="form-group full-width">
              <label>Photo de profil</label>
              <div className="photo-upload">
                <input
                  type="text"
                  value={formData.profile_photo}
                  onChange={(e) => setFormData({...formData, profile_photo: e.target.value})}
                  placeholder="URL de la photo"
                />
                <button type="button" className="upload-button">
                  <Upload size={20} />
                </button>
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button type="button" className="cancel-button" onClick={onClose}>
              Annuler
            </button>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? 'Enregistrement...' : beautician ? 'Modifier' : 'Ajouter'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BeauticianForm;