// src/pages/beautician/BeauticianProfile.js
import React from 'react';
import BeauticianInfo from '../../components/beautician/BeauticianInfo';
import Portfolio from '../../components/beautician/PortFolio';
import ServicesList from '../../components/beautician/ServicesList';
import Reviews from '../../components/beautician/Reviews';
import './BeauticianProfile.css';

const BeauticianProfile = ({ beautician }) => {
  if (!beautician) {
    return <div>Profil non trouvé</div>;
  }

  return (
    <div className="beautician-profile-page">
      {/* Section Profile */}
      <section className="profile-section">
        <BeauticianInfo beautician={beautician} />
      </section>

      {/* Section Portfolio */}
      <section className="portfolio-section">
        <Portfolio beauticianName={beautician.name} />
        <Reviews/>
      </section>

      {/* Section ServicesList */}
      <section className="ServicesList-section">
        <ServicesList />
      </section>
    </div>
  );
};

export default BeauticianProfile;