import { beauticians, serviceCategories } from './beauticiansData';

// Fonction de recherche avec l'API gouvernementale
// Recherche de villes avec l'API gouvernementale
export const searchCities = async (query) => {
  try {
    const response = await fetch(
      `https://geo.api.gouv.fr/communes?nom=${encodeURIComponent(query)}&fields=nom,code,codeDepartement,departement,population&boost=population&limit=5`
    );
    const data = await response.json();
    return data.map(city => ({
      text: city.nom,
      type: 'ville',
      subtext: `${city.codeDepartement} - ${city.departement.nom}`,
      cityCode: city.code,
      population: city.population
    }));
  } catch (error) {
    console.error('Erreur lors de la recherche de villes:', error);
    return [];
  }
};

// Recherche d'adresses détaillées
export const searchAddresses = async (query) => {
  try {
    if (!query || query.length < 3) return [];

    const response = await fetch(
      `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`
    );
    const data = await response.json();
    
    return data.features.map(feature => ({
      type: feature.properties.type,
      text: feature.properties.label,
      city: feature.properties.city,
      postcode: feature.properties.postcode,
      coordinates: feature.geometry.coordinates.reverse(),
      subtext: `${feature.properties.context}`,
      cityCode: feature.properties.citycode
    }));
  } catch (error) {
    console.error('Erreur lors de la recherche d\'adresses:', error);
    return [];
  }
};

// Recherche combinée (villes et adresses)
export const searchLocations = async (query) => {
  try {
    if (!query || query.length < 3) return [];

    const [cities, addresses] = await Promise.all([
      searchCities(query),
      searchAddresses(query)
    ]);

    // On combine et trie les résultats (les villes d'abord, puis les adresses)
    return [
      ...cities.sort((a, b) => (b.population || 0) - (a.population || 0)),
      ...addresses
    ].slice(0, 8); // Limite à 8 résultats au total

  } catch (error) {
    console.error('Erreur lors de la recherche des lieux:', error);
    return [];
  }
};

// Fonction de filtrage des esthéticiennes améliorée
export const filterBeauticians = ({
  searchTerm = '',
  services = [],
  priceRange = [],
  rating = 0,
  availability = null,
  location = null,
  radius = 5000 // rayon en mètres
}) => {
  return beauticians.filter(beautician => {
    // Filtre par terme de recherche
    if (searchTerm && !matchesSearchTerm(beautician, searchTerm)) {
      return false;
    }

    // Filtre par services
    if (services.length > 0 && !hasRequiredServices(beautician, services)) {
      return false;
    }

    // Filtre par prix
    if (priceRange.length > 0 && !matchesPriceRange(beautician, priceRange)) {
      return false;
    }

    // Filtre par note minimum
    if (rating > 0 && beautician.rating < rating) {
      return false;
    }

    // Filtre par disponibilité
    if (availability && !isAvailable(beautician, availability)) {
      return false;
    }

    // Filtre par localisation et rayon
    if (location && !isWithinRadius(beautician, location, radius)) {
      return false;
    }

    return true;
  });
};

// Fonctions helpers améliorées
const matchesSearchTerm = (beautician, term) => {
  const searchTerm = term.toLowerCase();
  return (
    beautician.name.toLowerCase().includes(searchTerm) ||
    beautician.city.toLowerCase().includes(searchTerm) ||
    beautician.district.toLowerCase().includes(searchTerm) ||
    beautician.specialties.some(s => s.toLowerCase().includes(searchTerm))
  );
};

const hasRequiredServices = (beautician, serviceIds) => {
  return serviceIds.every(serviceId => {
    const serviceCategory = serviceCategories.find(cat => cat.id === serviceId);
    if (!serviceCategory) return false;
    
    if (Array.isArray(serviceCategory.value)) {
      // Pour les services avec plusieurs valeurs possibles (ex: Manucure/Pédicure)
      return serviceCategory.value.some(value => 
        beautician.specialties.includes(value)
      );
    }
    
    return beautician.specialties.includes(serviceCategory.value);
  });
};

const matchesPriceRange = (beautician, ranges) => {
  return ranges.includes(beautician.price_range);
};

const isAvailable = (beautician, timeSlot) => {
  // À implémenter selon les besoins
  return true;
};

const isWithinRadius = (beautician, location, radius) => {
  const distance = getDistance(
    beautician.coordinates.lat,
    beautician.coordinates.lng,
    location.lat,
    location.lng
  );
  return distance <= radius;
};

const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Rayon de la terre en mètres
  const φ1 = lat1 * Math.PI/180;
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c;
};

