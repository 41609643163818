import React from 'react';
import { MapPin, Star, Calendar } from 'lucide-react';
import './BeauticianInfo.css';

const BeauticianInfo = ({ beautician }) => {
  const renderStars = (rating) => {
    if (!rating) return null;
    
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 === 0.5;
    const totalStars = 5;

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <Star 
          key={`full-${i}`}
          className="star filled"
          size={16}
          fill="#bd193b"
          stroke="#bd193b"
          strokeWidth={2}
        />
      );
    }

    if (hasHalfStar) {
      stars.push(
        <div key="half" className="half-star-container">
          <Star 
            className="star half-filled"
            size={16}
            fill="#bd193b"
            stroke="#bd193b"
            strokeWidth={2}
          />
          <Star 
            className="star empty-half"
            size={16}
            fill="none"
            stroke="#bd193b"
            strokeWidth={2}
          />
        </div>
      );
    }

    const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <Star 
          key={`empty-${i}`}
          className="star"
          size={16}
          fill="none"
          stroke="#bd193b"
          strokeWidth={2}
        />
      );
    }

    return stars;
  };

  const handleBooking = () => {
    console.log('Booking for:', beautician.name);
  };

  const renderAvailability = () => {
    const availability = [
      { day: 'L', hours: '8h-19h' },
      { day: 'M', hours: '8h-19h' },
      { day: 'M', hours: '8h-19h' },
      { day: 'J', hours: '8h-19h' },
      { day: 'V', hours: '8h-19h' },
      { day: 'S', hours: '9h-17h' },
      { day: 'D', hours: 'Fermé' },
    ];
  
    return (
      <div className="availability-list">
        {availability.map((slot, index) => (
          <span key={index} className="availability-item">
            <span className="availability-day">{slot.day}</span>
            •
            <span className="availability-hours">{slot.hours}</span>
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="profile-header">
      {/* Colonne 1 - Photo */}
      <div className="profile-image-wrapper">
        <div className="profile-image-container">
          <img src={beautician.image} alt={beautician.name} className="profile-image" />
        </div>
      </div>
      
      {/* Colonne 2 - Informations */}
      <div className="profile-info">
        <h1>{beautician.name}</h1>
        <div className="location">
          <MapPin size={18} />
          <span>{beautician.city} - {beautician.district}</span>
        </div>
        <div className="rating-container">
          <div className="card-rating-container">
            <div className="card-rating">
              {renderStars(beautician.rating)}
            </div>
            <div className="card-reviews">
              <span className="reviews-count">{beautician.reviewsCount || 0}</span>
              <span className="reviews-text">avis</span>
            </div>
          </div>
        </div>
        <span className="price-range">{beautician.price_range}</span>
        
        <button className="booking-button" onClick={handleBooking}>
          <Calendar size={20} />
          <span>Prendre rendez-vous</span>
        </button>
      </div>

      {/* Colonne 3 - Spécialités */}
      <div className="profile-specialties">
        <h2>Spécialités</h2>
        <ul>
          {beautician.specialties.map((specialty, index) => (
            <li key={index}>{specialty}</li>
          ))}
        </ul>
      </div>

      {/* Colonne 4 - Disponibilités */}
      <div className="availability-section">
        <h2>Disponibilités</h2>
        {renderAvailability()}
      </div>
    </div>
  );
};

export default BeauticianInfo;