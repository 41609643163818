import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="text-center mb-4">Contactez-nous</h1>
            <div className="contact-form-container">
              <form>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Nom complet</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="name" 
                    placeholder="Votre nom"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input 
                    type="email" 
                    className="form-control" 
                    id="email" 
                    placeholder="votre@email.com"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea 
                    className="form-control" 
                    id="message" 
                    rows="5"
                    placeholder="Votre message..."
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                  Envoyer le message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;