import React from 'react'
import { FaAnglesLeft, FaAnglesRight } from 'react-icons/fa6'

const Footer = () => {
    return (
        <footer className="p-2 px-4 bottom-4 left-[50%] fixed flex items-center justify-center gap-3 bg-white border -translate-x-[50%] rounded shadow">
            <button><FaAnglesLeft /></button>
                Pages 1 / 1
            <button><FaAnglesRight /></button>
        </footer>
    )
}

export default Footer