import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import FiltersColumn from '../../components/search/FiltersColumn';
import ResultsColumn from '../../components/search/ResultsColumn';
import MapColumn from '../../components/search/MapColumn';
import { filterBeauticians } from '../../data/searchUtils';
import { beauticians, serviceCategories } from '../../data/beauticiansData';
import './Search.css';

const Search = () => {
  const [searchParams] = useSearchParams();
  
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [minimumRating, setMinimumRating] = useState(0);
  const [radius, setRadius] = useState(5000);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filteredBeauticians, setFilteredBeauticians] = useState(beauticians);
  const [selectedBeautician, setSelectedBeautician] = useState(null);

  // Initialisation des filtres à partir des paramètres d'URL
  useEffect(() => {
    const query = searchParams.get('q');
    const service = searchParams.get('service');
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');

    if (query) {
      setSearchQuery(query);
    }

    if (service) {
      const serviceCategory = serviceCategories.find(
        cat => cat.name.toLowerCase() === service.toLowerCase()
      );
      if (serviceCategory) {
        setSelectedServices([serviceCategory.id]);
      }
    }

    if (lat && lng) {
      setSelectedLocation({ lat: parseFloat(lat), lng: parseFloat(lng) });
    }
  }, [searchParams]);

  // Mise à jour des filtres
  const handleFilterChange = useCallback((filters) => {
    const filtered = filterBeauticians({
      searchTerm: searchQuery,
      services: filters.services,
      priceRange: filters.prices,
      rating: filters.rating,
      radius: filters.radius,
      location: filters.location
    });
    setFilteredBeauticians(filtered);
  }, [searchQuery]);

  // Gestion du clic sur un marqueur
  const handleMarkerClick = useCallback((beautician) => {
    setSelectedBeautician(beautician);
    // Vous pouvez ajouter ici d'autres actions à effectuer lors du clic sur un marqueur
    // Par exemple, centrer la carte sur l'esthéticienne, ouvrir une modal, etc.
    console.log('Esthéticienne sélectionnée:', beautician);
  }, []);

  useEffect(() => {
    handleFilterChange({
      services: selectedServices,
      prices: selectedPrices,
      rating: minimumRating,
      radius,
      location: selectedLocation
    });
  }, [searchQuery, selectedServices, selectedPrices, minimumRating, radius, selectedLocation, handleFilterChange]);

  return (
    <div className="search-page">
      <div className="search-container">
        <FiltersColumn
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          selectedPrices={selectedPrices}
          setSelectedPrices={setSelectedPrices}
          minimumRating={minimumRating}
          setMinimumRating={setMinimumRating}
          radius={radius}
          setRadius={setRadius}
          onLocationChange={setSelectedLocation}
        />
        <ResultsColumn 
          beauticians={filteredBeauticians}
          selectedBeautician={selectedBeautician}
        />
        <MapColumn
          beauticians={filteredBeauticians}
          selectedLocation={selectedLocation}
          radius={radius}
          onMarkerClick={handleMarkerClick}
          selectedBeautician={selectedBeautician}
        />
      </div>
    </div>
  );
};

export default Search;