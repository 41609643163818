import React from 'react';
import Hero from '../../components/hero/Hero';
import BeauticianCardList from '../../components/beautician/BeauticianCardList';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <Hero />
      
      {/* Section Esthéticiennes */}
      <section className="beauticians-section">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="section-title">Nos Talentueuses Esthéticiennes</h2>
            <p className="section-subtitle">
              Découvrez nos professionnelles passionnées et expérimentées
            </p>
          </div>
          <BeauticianCardList />
        </div>
      </section>

      {/* Features Section */}
      <section className="features py-5">
        <div className="container">
          <h2 className="text-center mb-5">Pourquoi choisir Planibeauty ?</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="feature-card text-center">
                <i className="bi bi-star mb-3"></i>
                <h3>Professionnelles qualifiées</h3>
                <p>Toutes nos esthéticiennes sont certifiées et expérimentées</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-card text-center">
                <i className="bi bi-geo-alt mb-3"></i>
                <h3>Proximité</h3>
                <p>Trouvez une esthéticienne près de chez vous</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-card text-center">
                <i className="bi bi-calendar-check mb-3"></i>
                <h3>Réservation simple</h3>
                <p>Prenez rendez-vous en quelques clics</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;